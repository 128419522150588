<template>
  <v-card tile>
    <v-card-text>
      <v-btn @click="debug([invoice, invoice_products])" v-if="$development">Test</v-btn>
      <loader v-model="isLoadingOrder"></loader>
      <v-row v-if="invoice">
        <v-col lg="6">
          <div class="content-column">
            <h3>{{ this.invoice.invoice_no }}</h3>
            <v-data-table
              :headers="[
                { text: $t('name'), align: 'start', sortable: false, value: 'name' },
                { text: $t('quantity'), align: 'start', sortable: false, value: 'quantity' },
                { text: $t('total'), align: 'start', sortable: false, value: 'total' },
                { text: '', align: 'start', sortable: false, value: 'action' },
              ]"
              :items="invoice.invoice_items"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:[`item.total`]="{ item }">
                {{ numberToNiceString(item.total, invoice.currency, 2) }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  @click="addToInvoice(item)"
                  color="primary"
                  small
                >{{ $t('invoices.rectify') }}</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col lg="6">
          <v-form ref="invoice_form">
            <div class="content-column">
              <div class="d-flex justify-space-between">
                <h3>{{ $t('invoices.rectification_preview') }}</h3>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="d-flex justify-space-between">
                <div></div>
                <div style="max-width: 200px;">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :return-value.sync="date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    :offset-overflow="true"
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        :label="$t('date')"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                    >
                    <v-btn
                      text
                      @click="menu = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
              <v-data-table
                :headers="[
                  { text: $t('name'), align: 'start', sortable: false, value: 'name' },
                  { text: $t('quantity'), align: 'start', sortable: false, value: 'quantity', width: '60px' },
                  { text: $t('tax'), align: 'start', sortable: false, value: 'tax_pct', width: '80px' },
                  { text: $t('invoices.unit_price'), align: 'start', sortable: false, value: 'unit_price', width: '90px' },
                  { text: $t('total'), align: 'end', sortable: false, value: 'total', width: '150px' },
                  { text: '', align: 'end', sortable: false, value: 'action', width: '50px' }
                ]"
                :items="invoice_products"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.quantity`]="{ item }">
                  <v-text-field 
                    type="number"
                    v-model="item.quantity" 
                    dense
                  ></v-text-field>
                </template>
                <template v-slot:[`item.tax_pct`]="{ item }">
                  <v-text-field
                    type="number"
                    v-model="item.tax_pct" 
                    dense
                    disabled
                    suffix="%"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.unit_price`]="{ item }">
                  <v-text-field
                    v-model="item.unit_price" 
                    disabled
                    dense
                  ></v-text-field>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  <div>{{ numberToNiceString(item.total, invoice.currency, 2) }}</div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    @click="removeFromInvoice(item)"
                    color="primary"
                    small
                  ><v-icon small>mdi-close</v-icon></v-btn>
                </template>
                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="3"></td>
                    <td>{{ $t('invoices.subtotal') }}</td>
                    <td>
                      <h4 class="text-right">{{ numberToNiceString(invoiceTotals.base, order.currency, 2) }}</h4>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>{{ $t('tax') }}</td>
                    <td>
                      <h4 class="text-right">{{ numberToNiceString(invoiceTotals.tax, order.currency, 2) }}</h4>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>{{ $t('total') }}</td>
                    <td>
                      <h4 class="text-right">{{ numberToNiceString(invoiceTotals.total, order.currency, 2) }}</h4>
                    </td>
                    <td></td>
                  </tr>
                </template>
              </v-data-table>
              <div class="mt-3">
                <v-textarea
                  outlined
                  v-model="notes"
                  :label="$t('invoices.notes')"
                  :hint="$t('invoices.notes_hint')"
                  height="65"
                ></v-textarea>
              </div>
              <div>
                <v-textarea
                  outlined
                  v-model="internal_notes"
                  :label="$t('invoices.internal_notes')"
                  :hint="$t('invoices.internal_notes_hint')"
                  height="65"
                ></v-textarea>
              </div>
              <div class="d-flex mt-5">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  elevation="1"
                  dark
                  @click="submitInvoice"
                >{{ $t('invoices.create_invoice') }}</v-btn>
              </div>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { INVOICE_MAKE_CREDIT } from '../../constants/endpoints';
import loader from '../loadingBox.vue';

export default {
  name: 'invoiceCreate',
  props: ['is-edit', 'invoice-data', 'order-id'],
  components: {
    loader
  },
  data() {
    return {
      order_id: null,
      order: {},
      orders: [],
      isLoadingOrders: false,
      invoice_products: [],
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      isLoadingOrder: false,
      paid: 0,
      to_be_paid: 0,
      notes: '',
      internal_notes: '',
      notify: false,
      deleted_ids: [],
      invoice: null
    }
  },
  computed: {
    allowSelectionOfOrders() {
      if(!this.orderId && !this.isEdit) {
        return true;
      }
      return false;
    },
    invoiceTotals() {
      var totals = {
        base: 0,
        tax: 0,
        total: 0
      };
      for(var i in this.invoice_products) {
        var base = this.invoice_products[i].subtotal;
        var tax = this.calculatePercent(base, this.invoice_products[i].tax_pct)
        totals.base += base;
        totals.tax += tax;
        totals.total += base+tax;
      }
      return totals;
    }
  },
  watch: {
    invoice_products: {
      deep: true,
      handler() {
        // console.log(this.invoice_products)
        this.invoice_products.map(ip => {
          let qpc = ip.is_deposit === 1 ? 1 : ip.order_product.quantity_per_carton
          ip.subtotal = ip.quantity*qpc*ip.unit_price;
          ip.total = this.calculatePercent(ip.quantity*qpc*ip.unit_price, ip.tax_pct, 'SUM');
          ip.invoice_item_id = ip.id;
          ip.name_es = ip.name;
          ip.name_en = ip.name;
          //ip.order_product_id = this.isEdit ? ip.order_product_id : ip.id;
        })
      }
    }
  },
  methods: {
    addToInvoice(product, manual = true) {
      product.tax_pct = product.tax_pct*1;//convert to number
      var exist = this.invoice_products.find(ip => ip.order_product.built_sku === product.order_product.built_sku);
      if(exist !== undefined) {
        this.$toasted.error(this.$t('invoices.item_added_already'));
        return;
      }
      var cloned = Object.assign({}, product);
      //cloned.quantity = cloned.quantity - cloned.quantity_already_invoiced;
      if(cloned.quantity === 0) {
        if(manual) {
          this.$toasted.error(this.$t('invoices.product_fully_invoiced'));
        }
        return;
      }
      this.invoice_products.push(cloned);
    },
    removeFromInvoice(product) {
      this.deleted_ids.push(product.id);
      this.invoice_products = this.invoice_products.filter(ip => ip.built_sku !== product.built_sku);
    },
    submitInvoice() {
      if(!this.$refs.invoice_form.validate()) {
        return false;
      }
      const invoice = {
        invoice_id: this.invoice.id,
        invoice_date: this.date,
        notes: this.notes,
        internal_notes: this.internal_notes,
        tax_base: this.invoiceTotals.base,
        tax: this.invoiceTotals.tax,
        already_invoiced: 0,
        subtotal: this.invoiceTotals.total,
        total: this.invoiceTotals.total,
        paid: this.paid,
        total_to_be_paid: this.to_be_paid,
        items: this.invoice_products,
        notify: this.notify ? 1 : 0,
        item_delete_ids: this.deleted_ids
      };
      this.isLoadingOrder = true;
      var action = this.buildEndpoint(INVOICE_MAKE_CREDIT);
      this.$http.post(action, invoice)
      .then(resp => {
        this.isLoadingOrder = false;
        if(resp.data.result === true) {
          var message = this.$t('invoices.invoice_created');
          this.$toasted.success(message);
          this.$emit('success');
        } else {
          this.$toasted.error(resp.data.response_message);
        }
      })
    }
  },
  mounted() {
    this.invoice = this.invoiceData.invoice;
  }
}
</script>

<style scoped>
.content-column {
  border: 1px solid #E2E2E2;
  padding: 10px;
  border-radius: 3px;
}
</style>