var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[(_vm.$development)?_c('v-btn',{on:{"click":function($event){return _vm.debug([_vm.invoice, _vm.invoice_products])}}},[_vm._v("Test")]):_vm._e(),_c('loader',{model:{value:(_vm.isLoadingOrder),callback:function ($$v) {_vm.isLoadingOrder=$$v},expression:"isLoadingOrder"}}),(_vm.invoice)?_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"content-column"},[_c('h3',[_vm._v(_vm._s(this.invoice.invoice_no))]),_c('v-data-table',{attrs:{"headers":[
              { text: _vm.$t('name'), align: 'start', sortable: false, value: 'name' },
              { text: _vm.$t('quantity'), align: 'start', sortable: false, value: 'quantity' },
              { text: _vm.$t('total'), align: 'start', sortable: false, value: 'total' },
              { text: '', align: 'start', sortable: false, value: 'action' } ],"items":_vm.invoice.invoice_items,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberToNiceString(item.total, _vm.invoice.currency, 2))+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.addToInvoice(item)}}},[_vm._v(_vm._s(_vm.$t('invoices.rectify')))])]}}],null,true)})],1)]),_c('v-col',{attrs:{"lg":"6"}},[_c('v-form',{ref:"invoice_form"},[_c('div',{staticClass:"content-column"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',[_vm._v(_vm._s(_vm.$t('invoices.rectification_preview')))])]),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div'),_c('div',{staticStyle:{"max-width":"200px"}},[_c('v-menu',{ref:"menu",attrs:{"return-value":_vm.date,"close-on-content-click":false,"transition":"scale-transition","offset-overflow":true,"min-width":"auto","left":""},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('date'),"append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,false,1487439537),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1)],1)]),_c('v-data-table',{attrs:{"headers":[
                { text: _vm.$t('name'), align: 'start', sortable: false, value: 'name' },
                { text: _vm.$t('quantity'), align: 'start', sortable: false, value: 'quantity', width: '60px' },
                { text: _vm.$t('tax'), align: 'start', sortable: false, value: 'tax_pct', width: '80px' },
                { text: _vm.$t('invoices.unit_price'), align: 'start', sortable: false, value: 'unit_price', width: '90px' },
                { text: _vm.$t('total'), align: 'end', sortable: false, value: 'total', width: '150px' },
                { text: '', align: 'end', sortable: false, value: 'action', width: '50px' }
              ],"items":_vm.invoice_products,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","dense":""},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.tax_pct",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","dense":"","disabled":"","suffix":"%"},model:{value:(item.tax_pct),callback:function ($$v) {_vm.$set(item, "tax_pct", $$v)},expression:"item.tax_pct"}})]}},{key:"item.unit_price",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":"","dense":""},model:{value:(item.unit_price),callback:function ($$v) {_vm.$set(item, "unit_price", $$v)},expression:"item.unit_price"}})]}},{key:"item.total",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.numberToNiceString(item.total, _vm.invoice.currency, 2)))])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.removeFromInvoice(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"3"}}),_c('td',[_vm._v(_vm._s(_vm.$t('invoices.subtotal')))]),_c('td',[_c('h4',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.numberToNiceString(_vm.invoiceTotals.base, _vm.order.currency, 2)))])]),_c('td')]),_c('tr',[_c('td',{attrs:{"colspan":"3"}}),_c('td',[_vm._v(_vm._s(_vm.$t('tax')))]),_c('td',[_c('h4',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.numberToNiceString(_vm.invoiceTotals.tax, _vm.order.currency, 2)))])]),_c('td')]),_c('tr',[_c('td',{attrs:{"colspan":"3"}}),_c('td',[_vm._v(_vm._s(_vm.$t('total')))]),_c('td',[_c('h4',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.numberToNiceString(_vm.invoiceTotals.total, _vm.order.currency, 2)))])]),_c('td')])]},proxy:true}],null,true)}),_c('div',{staticClass:"mt-3"},[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('invoices.notes'),"hint":_vm.$t('invoices.notes_hint'),"height":"65"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1),_c('div',[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('invoices.internal_notes'),"hint":_vm.$t('invoices.internal_notes_hint'),"height":"65"},model:{value:(_vm.internal_notes),callback:function ($$v) {_vm.internal_notes=$$v},expression:"internal_notes"}})],1),_c('div',{staticClass:"d-flex mt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","elevation":"1","dark":""},on:{"click":_vm.submitInvoice}},[_vm._v(_vm._s(_vm.$t('invoices.create_invoice')))])],1)],1)])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }