<template>
  <v-container fluid>
    <PageHeader :title="$t('invoicing_title')" :subheader="subheader">
      <v-btn
        class="ma-2"
        :color="$store.getters.vColor"
        elevation="2"
        large
        dark
        @click="creating = true"
      >{{ $t('invoices.create_invoice') }}</v-btn>
    </PageHeader>
    <v-card>
      <v-card-text>
        <invoices-list 
          ref="invoice_list" 
          @editInvoice="getInvoice"
          @rectifyInvoice="getInvoice($event, 'rectify')"
        ></invoices-list>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="creating"
      fullscreen
    >
      <v-toolbar
        flat
        dark
        color="primary"
        class="pb-2"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="closeCreating"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <invoice-create
        v-if="creating && !rectify"
        :is-edit="invoiceEditData === null ? false : true"
        :invoice-data="invoiceEditData" 
        @success="reload"
      ></invoice-create>
      <invoice-rectifications
        v-if="creating && rectify"
        :is-edit="invoiceEditData === null ? false : true"
        :invoice-data="invoiceEditData" 
        @success="reload"
      ></invoice-rectifications>
    </v-dialog>
    <loader v-model="loading"></loader>
  </v-container>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import invoicesList from '../components/orders/invoices.vue';
import invoiceCreate from '../components/orders/invoiceCreate.vue';
import invoiceRectifications from '../components/orders/invoiceRectifications.vue';
import { INVOICE_GET } from '@/constants/endpoints.js';
import loader from '../components/loadingBox.vue';

export default {
  name: 'Orders',
  components: {
    PageHeader, invoicesList, invoiceCreate, loader, invoiceRectifications
  },
  data() {
    return {
      subheader: '',
      creating: false,
      loading: false,
      invoiceEditData: null,
      rectify: false
    }
  },
  computed: {
    title() {
      if(this.invoiceEditData !== null && this.invoiceEditData.invoice) {
        return this.$t('invoices.edit_invoice')+' '+this.invoiceEditData.invoice.invoice_no;
      }
      return this.$t('invoices.create_invoice')
    }
  },
  methods: {
    reload() {
      this.creating = false;
      this.$refs.invoice_list.loadInvoices();
    },
    getInvoice(invoice_id, action) {
      this.loading = true;
      this.rectify = action === 'rectify' ? true : false;
      this.$http.get(this.buildEndpoint(INVOICE_GET, { invoice_id: invoice_id }))
      .then(resp => {
        if(resp.data.result) {
          this.creating = true;
          this.invoiceEditData = resp.data.data;
        } else {
          this.$toasted.error(resp.data.response_message);
        }
      }).finally(() => this.loading = false);
    },
    closeCreating() {
      this.invoiceEditData = null;
      this.creating = false;
    }
  }
}

</script>

